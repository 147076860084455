const APIService = {
  headers: {
    Accept: "application/json",
  },
  async get(url, headers = {}) {
    return APIService.request(url, {
      method: "GET",
      headers: { ...this.headers, ...headers },
    });
  },
  async post(url, content, headers = {}) {
    return APIService.request(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...this.headers,
        ...headers,
      },
      body: JSON.stringify(content),
    });
  },
  async put(url, content, headers = {}) {
    return APIService.request(
      url,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(content),
      },
      true
    );
  },
  async request(url, init, put) {
    var res = await fetch(url, init);
    if (res.ok) {
      if (res.status === 200) {
        return put ? true : await res.json();
      }
      return undefined;
    }
    throw new Error(res.statusText);
  },
};

export default APIService;
