import React from "react";
import "./close-button.scss";

const CloseButton = ({ callBack }) => (
  <button
    className="button-close"
    onClick={(event) => {
      callBack && callBack(event);
    }}
  ></button>
);
export default CloseButton;
