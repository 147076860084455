import React, { useContext, useMemo } from "react";
import ContentContext from "../ContentContext";
import { NavLink, Route, Redirect } from "react-router-dom";
import "./menu.scss";
import NavMenu from "../navmenu";
import Cocktails from "./cocktails";
import Restaurant from "./restaurant";
import PageSwitch from "../PageSwitch";
import NotFound from "../NotFound";
import { useMobileScreenHeight } from "../hooks";

export default function Menu() {
  const {
    restaurant: { menuRedirections },
  } = useContext(ContentContext);
  const menuRedirection = useMemo(() => {
    const currentHours = new Date().getHours(),
      matchingTimeRange = menuRedirections.find(
        (timeRange) =>
          currentHours < timeRange.hours[1] &&
          currentHours >= timeRange.hours[0]
      );
    return matchingTimeRange ? matchingTimeRange.section : "cocktails";
  }, [menuRedirections]);

  const screenHeight = useMobileScreenHeight();
  return (
    <div className="menu page">
      <div className="menu-navbar">
        <NavMenu fixed />
        <nav
          className="nav-links"
          style={
            screenHeight
              ? {
                  top: `calc(${screenHeight}px - 2.5rem)`,
                  width: `calc(${screenHeight}px - 4rem)`, // 90deg rotated
                }
              : null
          }
        >
          <NavLink to="/menu/restaurant">Restaurant</NavLink>
          <NavLink to="/menu/cocktails">Cocktails</NavLink>
        </nav>
      </div>
      <PageSwitch classNames="fade" pathIndex={2} className="menu-section">
        <Route path="/menu/restaurant" exact component={Restaurant} />
        <Route path="/menu/cocktails/:cocktail?" exact component={Cocktails} />
        <Redirect from="/menu" exact to={"/menu/" + menuRedirection} />
        <Route path="*" component={NotFound} />
      </PageSwitch>
    </div>
  );
}
