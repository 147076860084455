import React from "react";
import { Helmet } from "react-helmet";

export default function ({
  title,
  url,
  description,
  keywords,
  image,
  children,
}) {
  const titleStr = title
    ? title + " - " + process.env.REACT_APP_SITE_NAME
    : process.env.REACT_APP_SITE_NAME + ", cocktails and pyjamas";
  return (
    <Helmet>
      <title>{titleStr}</title>
      <link rel="canonical" href={process.env.REACT_APP_EXTERNAL_HOST + url} />
      <meta name="robots" content="all" />
      <meta name="description" content={description} />
      <meta
        name="keywords"
        content={keywords || process.env.REACT_APP_KEYWORDS}
      />
      <meta property="og:site_name" content={process.env.REACT_APP_SITE_NAME} />
      <meta
        property="og:url"
        content={process.env.REACT_APP_EXTERNAL_HOST + url}
      />
      <meta
        property="og:title"
        content={(title ? title + " - " : "") + process.env.REACT_APP_SITE_NAME}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={
          process.env.REACT_APP_EXTERNAL_HOST +
          (image || "/images/SerpenAPlume_Front.jpg")
        }
      />
      <meta
        name="twitter:site"
        content={process.env.REACT_APP_TWITTER_HANDLE}
      />
      <meta
        name="twitter:creator"
        content={process.env.REACT_APP_TWITTER_HANDLE}
      />
      <meta name="twitter:title" content={titleStr} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:image"
        content={
          process.env.REACT_APP_EXTERNAL_HOST +
          (image || "/images/SerpenAPlume_Front.jpg")
        }
      />
      {children}
    </Helmet>
  );
}
