import React, { useEffect, useState, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import CloseButton from "../close-button";
import { useScript } from "../hooks";
import { BusinessInquiries } from "../business-inquiries";
import ContentContext from "../ContentContext";
import "./open-table.scss";

export function OpenTable({ children, className }) {
  const {
    front: {
      externalAccounts: { openTable = "" },
    },
  } = useContext(ContentContext);
  const [open, setOpen] = useState(false);
  useScript(
    openTable
      ? "https://www.opentable.com/widget/reservation/loader?rid=" +
          openTable +
          "&type=standard&theme=tall&iframe=false&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website"
      : "",
    "open-table-" + openTable
  );
  useEffect(() => {
    const otWidget = document.getElementById("ot-reservation-widget");
    otWidget &&
      (open
        ? otWidget.classList.add("show")
        : otWidget.classList.remove("show"));
  }, [open]);
  return (
    <>
      <button
        className={className}
        onClick={() => setOpen(true)}
        title="Book a table"
      >
        {children}
      </button>
      <CSSTransition in={open} classNames="fade" timeout={300} unmountOnExit>
        <div
          className="wrapper fixed top left above open-table-bg"
          style={{
            backgroundColor: "rgba(0,0,0,0.7)",
          }}
          onClick={(event) => {
            event.stopPropagation();
            setOpen(false);
          }}
        >
          <CloseButton />
          <BusinessInquiries />
        </div>
      </CSSTransition>
    </>
  );
}
