import React, { useState, useEffect, useRef, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import Logo from "../logo";
import "../front/front.scss";
import ContentContext from "../ContentContext";

export default function Loader({ onLoaded }) {
  const [count, setCount] = useState(0),
    [delay, setDelay] = useState(true),
    [animate, setAnimate] = useState(false),
    [loaded, setLoaded] = useState(false);
  const countIncrement = useRef(0);
  const { preLoadImages } = useContext(ContentContext);
  useEffect(() => {
    !delay && countIncrement.current >= preLoadImages.length && onLoaded();
  }, [count, onLoaded, delay, preLoadImages.length]);

  useEffect(() => {
    if (preLoadImages && preLoadImages.length > 0) {
      countIncrement.current = 0;
      setCount(countIncrement.current);
      preLoadImages.forEach((image) => {
        const imgElement = new Image();
        imgElement.addEventListener("load", () => {
          setCount(countIncrement.current++);
        });
        imgElement.addEventListener("error", (event) => {
          console.warn("Loading of resource ", imgElement, " failed : ", event);
          setCount(countIncrement.current++);
        });
        imgElement.src = image;
      });
    }
  }, [preLoadImages]);

  useEffect(() => {
    setDelay(true);
    const timeoutId = window.setTimeout(() => {
      if (countIncrement.current >= preLoadImages.length) {
        setAnimate(true);
      } else setDelay(false);
    }, 500);
    return () => window.clearTimeout(timeoutId);
  }, [preLoadImages.length]);

  useEffect(() => {
    loaded && onLoaded();
  }, [loaded, onLoaded]);

  const loading =
    !delay && preLoadImages && preLoadImages.length > 0
      ? count / preLoadImages.length
      : 0;

  return (
    <CSSTransition in={!loaded} classNames="fade" timeout={1000} unmountOnExit>
      <div id="loader" className="page fixed top left front flex">
        <div className="front-content flex absolute">
          <Logo
            style={{ height: "20vh" }}
            progress={animate ? false : loading}
            animate={animate || loading >= 0.9}
            onAnimationEnd={() => setLoaded(true)}
          />
        </div>
      </div>
    </CSSTransition>
  );
}

Loader.defaultProps = {
  onLoaded: () => {},
};
