import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import classname from "classnames";
import { CSSTransition } from "react-transition-group";
import "./vimeo.scss";
import { getCSSVariable } from "../getCSSVariable";

export function Vimeo({
  url,
  poster,
  // eslint-disable-next-line no-unused-vars
  ready = () => {},
  onEnded = () => {},
  // eslint-disable-next-line no-unused-vars
  title,
  ...props
}) {
  const [play, setPlay] = useState(false);
  const [showPoster, setShowPoster] = useState(true);

  useEffect(() => {
    const keyboardControls = ({ key, preventDefault }) => {
      switch (key) {
        case " ":
          preventDefault();
          setPlay(!play);
          break;
        case "Escape":
          setPlay(false);
          setShowPoster(true);
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", keyboardControls);
    return () => {
      window.removeEventListener("keydown", keyboardControls);
    };
  }, [play]);
  return (
    <div className="vimeo-wrapper" {...props}>
      <CSSTransition
        in={showPoster}
        classNames="fade"
        unmountOnExit
        timeout={300}
      >
        <div
          className="poster"
          style={{ backgroundImage: `url(${poster})` }}
          onClick={() => setPlay(true)}
        >
          <svg
            viewBox="-500 -500 1000 1000"
            className={classname({
              loading: play && showPoster,
            })}
          >
            <circle
              stroke={getCSSVariable("gold")}
              strokeWidth="35"
              fill="none"
              cx="0"
              cy="0"
              r="435"
            />
            <polygon
              fill={getCSSVariable("gold")}
              points="-101, 175 202, 0 -101 -175"
            />
          </svg>
        </div>
      </CSSTransition>
      <ReactPlayer
        url={url}
        controls
        className="player"
        width="100%"
        height="100%"
        playing={play}
        config={{
          vimeo: {
            playerOptions: {
              playsinline: true,
            },
          },
        }}
        onStart={() => setShowPoster(false)}
        onPlay={() => setShowPoster(false)}
        onEnded={() => {
          setShowPoster(true);
          onEnded();
        }}
      />
    </div>
  );
}
