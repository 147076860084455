import React, { useContext } from "react";
import Cocktail from "./cocktail";
import Headers from "../headers";
import "./cocktails.scss";
import ContentContext from "../ContentContext";
import { useParams } from "react-router-dom";

export default function Cocktails() {
  const {
      restaurant: { cocktails },
    } = useContext(ContentContext),
    { cocktail } = useParams();
  return (
    <div className="cocktails paper">
      {!cocktail && (
        <Headers
          title="Cocktails"
          url="/menu/cocktails"
          keywords={
            cocktails.map((c) => c.title).join(",") +
            "," +
            process.env.REACT_APP_KEYWORDS
          }
          description="Enjoy one of our delightful cocktails while you are making the world a better place"
        />
      )}
      {cocktails.map((c) => (
        <Cocktail key={c.title} {...c} />
      ))}
    </div>
  );
}
