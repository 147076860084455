import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation, Switch } from "react-router-dom";

export default function PageSwitch({
  pathIndex,
  classNames,
  children,
  ...props
}) {
  const location = useLocation();
  const key =
    pathIndex > 0 ? location.pathname.split("/")[pathIndex] : location.key;
  return (
    <TransitionGroup {...props}>
      <CSSTransition
        key={key}
        classNames={classNames}
        timeout={300}
        unmountOnExit
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}
