import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import classnames from "classnames";
import LocationLink from "../location-link";
import { getNewItem } from "../services";
import NavMenu from "../navmenu";
import APIService from "../APIService";
import Headers from "../headers";
import ContentContext from "../ContentContext";
import { BusinessInquiries } from "../business-inquiries";
import { useMobileScreenHeight } from "../hooks";
import ContactImage from "./contact.jpg";
import PenImage from "./pen.svg";
import CameraImage from "./camera.png";
import CesioImage from "./cesio.png";
import "./contact.scss";

export default function Contact() {
  const [formIsValid, setFormIsValid] = useState(false),
    [sending, setSending] = useState(false),
    [error, setError] = useState(false),
    [response, setResponse] = useState(""),
    [drawing, setDrawing] = useState({}),
    [cocktail, setCocktail] = useState({ title: "" }),
    [cardBack, setCardBack] = useState(false),
    [visitCard, setVisitCard] = useState("");
  const {
    manifesto: {
      medias: { illustrations },
    },
    restaurant: { cocktails },
    concierge: { visitCards, writeUsMessage },
  } = useContext(ContentContext);

  const emailInputRef = useRef(null),
    nameInputRef = useRef(null),
    textAreaRef = useRef(null);
  const handleEmail = useCallback((event) => {
    setEmail(event.target.value);
    setFormIsValid(
      emailInputRef.current.checkValidity() &&
        nameInputRef.current.checkValidity() &&
        textAreaRef.current.checkValidity()
    );
  }, []);
  const handleName = useCallback((event) => {
    setName(event.target.value);
    setFormIsValid(
      emailInputRef.current.checkValidity() &&
        nameInputRef.current.checkValidity() &&
        textAreaRef.current.checkValidity()
    );
  }, []);
  const [email, setEmail] = useState(""),
    [name, setName] = useState(""),
    [message, setMessage] = useState("Dear Serpent,\n");
  const sendMessage = useCallback(() => {
    setSending(true);
    try {
      APIService.put(`${process.env.REACT_APP_API}/send`, {
        name: name,
        email: email,
        message: message,
      }).then(() => {
        setSending(false);
        setResponse("Message sent!");
        window.setTimeout(() => {
          setResponse("");
          setEmail("");
          setFormIsValid(false);
        }, 2500);
      });
    } catch {
      setSending(false);
      setError(true);
      setResponse(
        <div>
          <div>We couldn&lsquo;t send that,</div>
          <div>sorry.</div>
          <div>
            Please try contacting us directly at{" "}
            <a
              onClick={() => setResponse("")}
              href="mailto:serpent@plume.fun"
              target="_blank"
              rel="noopener noreferrer"
            >
              serpent@plume.fun
            </a>
          </div>
        </div>
      );
      window.setTimeout(() => {
        setResponse("");
      }, 15000);
    }
  }, [message, email, name]);

  // textArea
  const [shouldFocus, setShouldFocus] = useState(false);
  const focusTextArea = useCallback(() => {
    if (textAreaRef.current) {
      if (typeof textAreaRef.current.selectionStart == "number") {
        textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd =
          textAreaRef.current.value.length;
        textAreaRef.current.focus();
      } else if (typeof textAreaRef.current.createTextRange != "undefined") {
        textAreaRef.current.focus();
        const range = textAreaRef.current.createTextRange();
        range.collapse(false);
        range.select();
      }
      setShouldFocus(false);
    }
  }, []);
  const textAreaRefCallback = useCallback(
    (ref) => {
      textAreaRef.current = ref;
      shouldFocus && focusTextArea();
    },
    [shouldFocus, focusTextArea]
  );
  useEffect(() => {
    if (window.innerHeight <= window.innerWidth && window.innerHeight > 799) {
      setShouldFocus(true);
      focusTextArea();
    }
  }, [focusTextArea]);

  // write here message animation
  const animationIntervalIdRef = useRef();
  useEffect(() => {
    if (writeUsMessage) {
      const writeHereMessageChars = [...writeUsMessage];
      const animationTimeoutId = window.setTimeout(() => {
        animationIntervalIdRef.current = window.setInterval(() => {
          if (writeHereMessageChars.length) {
            setMessage((message) => message + writeHereMessageChars.shift());
          } else {
            // wait then do reverse animation
            window.clearInterval(animationIntervalIdRef.current);
            animationIntervalIdRef.current = window.setTimeout(() => {
              writeHereMessageChars.push(...[...writeUsMessage]);
              animationIntervalIdRef.current = window.setInterval(() => {
                if (writeHereMessageChars.length) {
                  setMessage((message) => message.slice(0, -1));
                  writeHereMessageChars.pop();
                } else {
                  window.clearInterval(animationIntervalIdRef.current);
                }
              }, 50);
            }, 1500);
          }
        }, 75);
      }, 3500);
      return () => {
        window.clearTimeout(animationTimeoutId);
        setMessage("Dear Serpent,\n");
      };
    }
  }, [writeUsMessage]);
  const handleTextAreaFocus = useCallback(() => {
    if (animationIntervalIdRef.current) {
      window.clearInterval(animationIntervalIdRef.current);
      animationIntervalIdRef.current = null;
      setMessage("Dear Serpent,\n");
    }
    focusTextArea();
  }, [focusTextArea]);

  const handleMessage = useCallback((event) => {
    if (animationIntervalIdRef.current) {
      window.clearInterval(animationIntervalIdRef.current);
      animationIntervalIdRef.current = null;
      setMessage("Dear Serpent,\n" + event.nativeEvent.data);
    } else {
      setMessage(event.target.value);
      setFormIsValid(
        emailInputRef.current.checkValidity() &&
          nameInputRef.current.checkValidity() &&
          textAreaRef.current.checkValidity()
      );
    }
  }, []);

  // Keep signature block aligned with paper lines
  const [signatureBottomPosition, setSignatureBottomPosition] = useState(22);
  const papersheetRef = useRef();
  useEffect(() => {
    const positionSignatureOnResize = () => {
      papersheetRef.current &&
        setSignatureBottomPosition(
          papersheetRef.current.getBoundingClientRect().height % 21
        );
    };
    positionSignatureOnResize();
    window.addEventListener("resize", positionSignatureOnResize);
    return () =>
      window.removeEventListener("resize", positionSignatureOnResize);
  }, []);

  useEffect(() => {
    illustrations.length && setDrawing(getNewItem(illustrations));
  }, [illustrations]);
  useEffect(() => {
    cocktails.length && setCocktail(getNewItem(cocktails) || { title: "" });
  }, [cocktails]);
  useEffect(() => {
    visitCards.length && setVisitCard(getNewItem(visitCards));
  }, [visitCards]);

  const screenHeight = useMobileScreenHeight();
  return (
    <div className={classnames("page contact", { sending })}>
      <Headers
        title="Contact Us"
        url="/contact"
        description="Write us your thoughts, we're here for you."
        image={ContactImage}
      />
      <NavMenu />
      <BusinessInquiries short />
      <div
        className="papersheet"
        style={screenHeight ? { height: screenHeight * 0.9 - 42 } : null}
        ref={papersheetRef}
      >
        <CSSTransition
          in={sending || Boolean(response)}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          <div className="wrapper absolute left top sending-message">
            <p>
              {sending && "Sending your message"}
              {response}
            </p>
          </div>
        </CSSTransition>
        <div className="left-margin" />
        <LocationLink location="Serpent A Plume, Place des Vosges, Paris">
          <div className="address">
            Serpent à Plume <br />
            <span
              style={{
                fontSize: "0.6rem",
                letterSpacing: "1.15px",
                lineHeight: "1",
              }}
            >
              Place des Vosges
              <br />
              Paris
            </span>
          </div>
        </LocationLink>
        <textarea
          ref={textAreaRefCallback}
          value={message}
          onChange={handleMessage}
          onFocus={handleTextAreaFocus}
        />
        <div className="signature" style={{ bottom: signatureBottomPosition }}>
          <input
            type="text"
            required
            min="3"
            placeholder="Please sign here"
            value={name}
            onChange={handleName}
            ref={nameInputRef}
          />
          <input
            type="email"
            required
            placeholder="your@email.address"
            value={email}
            onChange={handleEmail}
            ref={emailInputRef}
          />
        </div>
        <button
          title={
            !formIsValid || error
              ? "Did you miss something? Please check the form"
              : ""
          }
          disabled={!formIsValid || error}
          className="send btn"
          onClick={sendMessage}
          style={{ bottom: signatureBottomPosition }}
        >
          Send
        </button>
      </div>
      {illustrations.length && (
        <Link to="/manifesto/illustrations" title="Tea">
          <img src={drawing.url} className="flyer" alt="Tea artwork" />
        </Link>
      )}
      <div
        className="visit-card background-image"
        onClick={() => setCardBack(!cardBack)}
      >
        <CSSTransition
          in={cardBack}
          timeout={100}
          classNames="fade"
          unmountOnExit
        >
          <img src={visitCard} alt="Tea artwork" className="wrapper" />
        </CSSTransition>
      </div>
      <Link to={"/menu/cocktails/" + cocktail.uri} title="Thirsty you">
        <img src={cocktail.front} className="cocktail" alt={cocktail.title} />
      </Link>
      <Link to="/manifesto/films" title="XXX" className="hide-mobile">
        <img src={CameraImage} className="camera" alt="Super 8 frenzy" />
      </Link>
      <img
        className="pen"
        src={PenImage}
        alt="a magnificient foutain pen to write love letters"
      />
      <a
        href="https://www.bloomberg.com/quote/LIVE:US"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={CesioImage} className="patch" alt="MONEY" />
      </a>
      <a
        href="mailto:paul@paulcordonnier?subject=Serpent%20A%20Plume%20website&body=Hey%2C%0A"
        className="trademark"
        target="_blank"
        rel="noreferrer noopener"
      >
        made with ♡ by Paul
      </a>
    </div>
  );
}

Contact.defaultProps = {};
