import React, { useMemo } from "react";
import { useIsSafari } from "../hooks";

const LocationLink = ({ location, children, className }) => {
  const isSafari = useIsSafari();
  const locationService = useMemo(() => {
    if (window.navigator.userAgent.toLowerCase().indexOf("android") >= 0) {
      return "geo:0,0?q=";
    }
    if (isSafari) {
      return "https://maps.apple.com/?q=";
    }
    return "https://www.google.com/maps?q=";
  }, [isSafari]);
  const href = useMemo(
    () => locationService + encodeURI(location),
    [locationService, location]
  );
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className + " location-link"}
    >
      {children}
    </a>
  );
};

export default LocationLink;
