/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback, useMemo, useRef } from "react";
import classnames from "classnames";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  useHistory,
  useParams,
} from "react-router-dom";
import PageSwitch from "../PageSwitch";
import { Vimeo } from "../vimeo";
import ReactPlayer from "react-player";
import "./carousel.scss";
import { getNewIndex } from "../services";

const Media = ({
  type,
  url,
  selected,
  title,
  poster,
  hidden,
  above,
  ...props
}) => {
  if (!url) return null;
  switch (type) {
    case "vimeo":
      return <Vimeo url={url} poster={poster} {...props} />;
    case "video":
      return (
        <ReactPlayer
          controls
          src={url}
          width="100%"
          height="100%"
          config={{
            file: {
              poster: poster,
              title: title,
            },
          }}
          {...props}
        />
      );
    case "image":
    default:
      return <img src={url} title={title} alt={title} {...props} />;
  }
};
export default function Carousel({
  medias,
  dots,
  control,
  className,
  basePath,
  random,
}) {
  const { media } = useParams();
  const history = useHistory();

  const getUri = useCallback(
    (media = {}) => basePath + "/" + (media.uri || medias.indexOf(media)),
    [medias, basePath]
  );

  const previous = useMemo(() => {
    let index = medias.findIndex((m) => m.uri === media);
    if (index < 0) index = +media;
    if (isNaN(index)) {
      return getUri(medias[0]);
    } else if (index <= 0) {
      return getUri(medias[medias.length - 1]);
    } else {
      return getUri(medias[index - 1]);
    }
  }, [medias, media, getUri]);
  const next = useMemo(() => {
    let index = medias.findIndex((m) => m.uri === media);
    if (index < 0) index = +media;
    if (isNaN(index)) {
      return getUri(medias[0]);
    } else if (index >= medias.length - 1) {
      return getUri(medias[0]);
    } else {
      return getUri(medias[index + 1]);
    }
  }, [medias, media, getUri]);

  useEffect(() => {
    const keyboardControls = ({ key }) => {
      switch (key) {
        case "ArrowLeft":
          history.push(previous);
          break;
        case "ArrowRight":
          history.push(next);
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", keyboardControls);
    return () => {
      window.removeEventListener("keydown", keyboardControls);
    };
  }, [previous, next, history]);
  const startMovePositionRef = useRef();
  const moveStart = useCallback((e) => {
    startMovePositionRef.current = (
      e.changedTouches ? e.changedTouches[0] : e
    ).clientX;
  }, []);
  const moveEnd = useCallback(
    (e) => {
      if (startMovePositionRef.current || startMovePositionRef.current === 0) {
        const distance =
          (e.changedTouches ? e.changedTouches[0] : e).clientX -
          startMovePositionRef.current;
        if (distance < -10) {
          history.push(next);
        } else if (distance > 10) {
          history.push(previous);
        }
        startMovePositionRef.current = null;
      }
    },
    [previous, next, history]
  );

  return (
    <div className={classnames("carousel", className)}>
      <PageSwitch classNames="switch" className="carousel-medias" pathIndex={3}>
        {medias.map((m, i) => (
          <Route path={getUri(m)} key={i}>
            <Media
              {...m}
              onEnded={() => history.push(next)}
              onMouseDown={moveStart}
              onTouchStart={moveStart}
              onMouseUp={moveEnd}
              onTouchEnd={moveEnd}
            />
          </Route>
        ))}
        {medias[0] && (
          <Redirect
            from={basePath}
            to={getUri(medias[random ? getNewIndex(medias) : 0])}
          />
        )}
      </PageSwitch>
      {control && medias.length > 1 && (
        <nav className="carousel-controls">
          <Link to={previous} className="previous" replace />
          <Link to={next} className="next" replace />
          {dots && (
            <ul>
              {medias.map((m, i) => (
                <NavLink to={getUri(m)} key={i} title={m.title} replace>
                  <li></li>
                </NavLink>
              ))}
            </ul>
          )}
        </nav>
      )}
    </div>
  );
}
Carousel.defaultProps = {
  index: 0,
  medias: [],
  dots: true,
  control: true,
  onMediaChange: () => {},
};
