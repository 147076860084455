import React, { useContext, useMemo } from "react";
import ContentContext from "../ContentContext";

export function BusinessInquiries({ short }) {
  const {
    front: {
      businessInquiries: { longText, shortText, subject, body },
    },
  } = useContext(ContentContext);
  const emailHref = useMemo(
    () =>
      "mailto:serpent@plume.fun?subject=" +
      encodeURI(subject) +
      "&body=" +
      encodeURI(body),
    [subject, body]
  );
  return (
    <a
      className="business-inquiries"
      title={short ? longText : ""}
      href={emailHref}
    >
      {short ? shortText : longText}
    </a>
  );
}
