import React, { useState, useEffect } from "react";
import APIService from "./APIService";

const defaultContent = {
  front: {
    catchPhrases: [],
    externalAccounts: {},
    businessInquiries: {},
    films: [],
  },
  manifesto: {
    manifesto: "",
    medias: {
      photos: [],
      illustrations: [],
      films: [],
    },
    filmCategories: [],
  },
  restaurant: {
    cocktails: [],
    menu: [],
    menuRedirections: [],
  },
  concierge: {
    visitCards: [],
  },
  preLoadImages: [],
};
const ContentContext = React.createContext(defaultContent);

export function ContentProvider({ children }) {
  const [content, setContent] = useState(defaultContent);
  useEffect(() => {
    (async () => {
      const content = await APIService.get(
        process.env.REACT_APP_CONTENT_BASE_URL +
          process.env.REACT_APP_CONTENT_FILE
      );
      const preLoadImages = [
        ...content.restaurant.cocktails.map(({ front }) => front),
        ...content.concierge.visitCards,
      ];
      setContent({ ...content, preLoadImages });
    })();
  }, []);
  return (
    <ContentContext.Provider value={content}>
      {children}
    </ContentContext.Provider>
  );
}

export default ContentContext;
