import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import ReactPlayer from "react-player";
import { Link, NavLink } from "react-router-dom";
import Headers from "../headers";
import Logo from "../logo";
import LocationLink from "../location-link";
import { getNewItem, shuffle } from "../services";
import "./front.scss";
import ContentContext from "../ContentContext";
import { OpenTable } from "../open-table";
import classname from "classnames";
import { CSSTransition } from "react-transition-group";
import { getCSSVariable } from "../getCSSVariable";

const checkTime = (setOpenedTitle) => {
  const closedDays = [1, 2],
    openedHours = [11, 2],
    today = new Date(),
    indexOfDay = closedDays.indexOf(today.getDay()),
    dayBefore =
      closedDays[indexOfDay] - 1 >= 0 ? closedDays[indexOfDay] - 1 : 6,
    hour = today.getHours();
  if (
    (indexOfDay === -1 && hour > openedHours[0]) ||
    (closedDays.indexOf(dayBefore) === -1 && hour < openedHours[1])
  ) {
    setOpenedTitle("We're open, come and join!");
    if (hour + 1 === openedHours[1])
      window.setTimeout(() => {
        checkTime(setOpenedTitle);
      }, 3000);
  } else {
    setOpenedTitle("Sorry, we should be closed now");
    if (hour - 1 === openedHours[0])
      window.setTimeout(() => {
        checkTime(setOpenedTitle);
      }, 3000);
  }
};

const Front = () => {
  const [catchPhrase, setCatchPhrase] = useState("");
  const {
    front: {
      catchPhrases,
      externalAccounts: { instagram = "" },
      films: frontPageFilms,
      openPhrase,
    },
  } = useContext(ContentContext);
  useEffect(() => {
    setCatchPhrase(getNewItem(catchPhrases));
  }, [catchPhrases]);

  const [openedTitle, setOpenedTitle] = useState(
    "No time for that, come to have a drink whenever you can"
  );
  useEffect(() => {
    checkTime(setOpenedTitle);
  }, []);

  const [film, setFilm] = useState({}),
    [filmLoaded, setFilmLoaded] = useState(false),
    [showPlayBtn, setShowPlayBtn] = useState(false);
  const films = useMemo(() => shuffle(frontPageFilms), [frontPageFilms]);
  const setNewFilm = useCallback(() => {
    setFilmLoaded(false);
    const index = films.indexOf(film) + 1;
    setFilm(index >= films.length ? films[0] : films[index]);
  }, [films, film]);
  useEffect(() => {
    films.length && films.indexOf(film) < 0 && setNewFilm();
  }, [films, film, setNewFilm]);
  useEffect(() => {
    if (filmLoaded) {
      setShowPlayBtn(true);
      const timeoutId = window.setTimeout(() => setShowPlayBtn(false), 3000);
      return () => window.clearTimeout(timeoutId);
    }
  }, [filmLoaded]);
  const [videoRatio, setVideoRatio] = useState();

  return (
    <div className="page front flex wrapper absolute top left">
      <Headers description={catchPhrases[0]} />
      {film.url && (
        <CSSTransition in={filmLoaded} classNames="fade" timeout={300}>
          <div
            className="front-movie-bg"
            style={{
              minWidth: `calc(100vh * ${videoRatio})`,
              minHeight: `calc(100vw / ${videoRatio})`,
            }}
          >
            <ReactPlayer
              url={film.url}
              className="player"
              width="100%"
              height="100%"
              config={{
                vimeo: {
                  playerOptions: {
                    background: true,
                    pip: false,
                    autoplay: true,
                    muted: true,
                    playsinline: true,
                  },
                  file: { attributes: { disablepictureinpicture: "true" } },
                },
              }}
              playing
              muted
              onPlay={() => setFilmLoaded(true)}
              onEnded={setNewFilm}
              pip={false}
              controls={false}
              ref={(ref) => {
                const vimeoIframeElement = ref?.getInternalPlayer()?.element;
                const [vimeoIframeWidth, vimeoIframeHeight] = [
                  vimeoIframeElement?.getAttribute("width") ?? 4,
                  vimeoIframeElement?.getAttribute("height") ?? 3,
                ];
                setVideoRatio(vimeoIframeWidth / vimeoIframeHeight);
              }}
            />
          </div>
        </CSSTransition>
      )}
      <div className="front-content flex absolute">
        <Link
          to={"/manifesto/films/" + film.uri}
          className={classname("logo-wrapper", {
            active: showPlayBtn,
          })}
        >
          <Logo style={{ height: "20vh" }} />
          <svg viewBox="-500 -500 1000 1000" className="play">
            <polygon
              stroke={getCSSVariable("gold")}
              strokeWidth={20}
              fill="transparent"
              points="-101, 175 202, 0 -101 -175"
            />
          </svg>
        </Link>
        <p className="catchPhrase">“&nbsp;{catchPhrase}&nbsp;”</p>
        <div className="info">
          <nav className="nav-links main-links">
            <NavLink to="/manifesto">Manifesto</NavLink>
            <NavLink to="/menu">Menu</NavLink>
            <NavLink to="/contact">Concierge</NavLink>
          </nav>
          <div className="nav-links secondary-links">
            <OpenTable className="btn-link">Reserve</OpenTable>
            <a
              href={"https://www.instagram.com/" + instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              Follow
            </a>
          </div>
          <p className="informations" title={openedTitle}>
            {openPhrase}
            <br />
            <LocationLink location="Serpent A Plume, Place des Vosges, Paris">
              24 Place des Vosges <br />
              Paris
            </LocationLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Front;
