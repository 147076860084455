export const getNewIndex = (array, current) => {
  const oldIndex = array.indexOf(current);
  let newIndex = oldIndex;
  while (newIndex === oldIndex) {
    newIndex = Math.floor(Math.random() * array.length);
  }
  return newIndex;
};
export const getNewItem = (array, current) => {
  return array[getNewIndex(array, current)];
};

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
export const shuffle = (a) => {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};
