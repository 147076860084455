import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classname from "classnames";
import "./navmenu.scss";
import Logo from "../logo";

export default function NavMenu({ color, fixed }) {
  const [showArrow, setShowArrow] = useState(false);
  useEffect(() => {
    if (
      !(
        window.sessionStorage &&
        window.sessionStorage.getItem("navmenu-touched")
      )
    ) {
      setShowArrow(true);
    }
  }, []);
  return (
    <div
      className={classname("nav-menu", { fixed })}
      onClick={() =>
        window.sessionStorage &&
        window.sessionStorage.setItem("navmenu-touched", true)
      }
      style={{ color: color }}
    >
      <Link to="/">
        <Logo noText fill={color} />
        <CSSTransition in={showArrow} classNames="arrow" timeout={300}>
          <div className="back-arrow">↢</div>
        </CSSTransition>
      </Link>
    </div>
  );
}
