import React, { useEffect, useMemo, useRef } from "react";
import Headers from "../headers";
import classname from "classnames";
import { Link, useParams } from "react-router-dom";

export default function Cocktail({
  title,
  uri,
  price,
  ingredients,
  front,
  // eslint-disable-next-line no-unused-vars
  index,
  ...props
}) {
  const cocktailRef = useRef();
  const { cocktail } = useParams();
  const active = useMemo(() => cocktail === uri, [cocktail, uri]);
  useEffect(() => {
    active &&
      cocktailRef.current &&
      cocktailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  }, [active]);

  const rotation = useMemo(() => (Math.random() - 0.5) * 20, []);

  return (
    <div
      {...props}
      className={classname("cocktail", { active })}
      ref={cocktailRef}
      style={{ transform: "rotate(" + rotation + "deg)" }}
    >
      {active && (
        <Headers
          title={title}
          url={"/menu/cocktails/" + uri}
          keywords={
            ingredients.join(", ") +
            ", cocktail, barman, mixologist, " +
            process.env.REACT_APP_KEYWORDS
          }
          description={ingredients.join(", ")}
          image={front}
        />
      )}
      <Link to={"/menu/cocktails/" + uri} className="cocktail-link" replace>
        <img className="cocktail-img" src={front} alt={title} />
      </Link>
      <div
        {...props}
        className="cocktail-content wrapper absolute top left background-image"
        style={{
          backgroundImage: "url(/images/postalcard.jpg)",
        }}
      >
        <h1 className="cocktail-title">{title}</h1>
        <ul>
          {ingredients.map((i) => (
            <li key={i}>
              <span>{i}</span>
            </li>
          ))}
        </ul>
        <div className="cocktail-price">{price}</div>
      </div>
    </div>
  );
}
