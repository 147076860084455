import React, { useContext, useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import classname from "classnames";
import Carousel from "../carousel";
import ContentContext from "../ContentContext";
import { shuffle } from "../services";

export function FilmsCarousel({ films }) {
  const history = useHistory();
  const {
    manifesto: { filmCategories: categories },
  } = useContext(ContentContext);
  const [category, setCategory] = useState("");
  const randomlyChoosableCategories = useMemo(
    () =>
      shuffle([
        ...new Set(films.filter((f) => f.category).map((f) => f.category)),
      ]),
    [films]
  );
  const { media } = useParams();
  const medias = useMemo(
    () =>
      films
        .filter((f) => f.category === category)
        .sort((a, b) => (b.above ? 1 : 0)),
    [films, category]
  );
  useEffect(() => {
    if (
      !category &&
      randomlyChoosableCategories &&
      randomlyChoosableCategories.length
    ) {
      if (media) {
        const { category: requestedCategory = "" } = films.find(
          (film) => film.uri === media
        );
        requestedCategory &&
        categories.some((category) => category === requestedCategory)
          ? setCategory(requestedCategory)
          : history.replace("/manifesto/films");
      } else {
        setCategory(randomlyChoosableCategories[0]);
      }
    }
  }, [
    randomlyChoosableCategories,
    media,
    history,
    films,
    categories,
    category,
  ]);
  return (
    <>
      <Carousel
        medias={medias}
        basePath="/manifesto/films"
        className="films"
        dots={false}
      />
      <nav className="films-categories">
        {categories.map((cat) => (
          <button
            key={cat}
            className={classname("btn-link", { active: cat === category })}
            onClick={() => setCategory(cat)}
          >
            {cat}
          </button>
        ))}
      </nav>
    </>
  );
}
