import React, { useContext, useEffect, useState } from "react";
import Headers from "../headers";
import "./restaurant.scss";
import ContentContext from "../ContentContext";
import { OpenTable } from "../open-table";

export default function () {
  const {
    restaurant: { menu },
  } = useContext(ContentContext);
  const [pieces, setPieces] = useState(false);
  useEffect(() => {
    menu.some((course) =>
      course.dishes.some((dish) => dish.pieces && dish.pieces.length)
    )
      ? setPieces(true)
      : setPieces(false);
  }, [menu]);
  return (
    <div className="paper flex restaurant">
      <Headers
        title="Restaurant"
        url="/menu/restaurant"
        description="Amazing meals served day and night"
      />
      <div className="course-row">
        <div></div>
        <OpenTable className="btn-reserve btn">Réservation</OpenTable>
      </div>
      {menu.map((section, i) => (
        <div className="course" key={section.title}>
          <div className="course-row">
            <h2>{section.title}</h2>
            {i === 0 && pieces && (
              <div className="dish-pricing">
                <div className="dish-pricing-col course-title">Pièces</div>
                <div className="dish-pricing-col course-title">Prix</div>
              </div>
            )}
          </div>
          {section.description && (
            <blockquote>{section.description}</blockquote>
          )}
          {section.dishes.map(({ name, pieces, prices, price, toppings }) => (
            <div className="dish" key={name}>
              <div className="course-row">
                <div className="dish-name">{name}</div>
                <div className="dish-pricing">
                  {pieces ? (
                    <>
                      <div className="course-col dish-pieces">
                        {pieces.map((piece) => (
                          <div className="dish-pricing-col" key={piece}>
                            {piece}
                          </div>
                        ))}
                      </div>
                      <div className="course-col dish-prices">
                        {prices.map((price) => (
                          <div className="dish-pricing-col" key={price}>
                            {price}
                          </div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="course-col dish-pricing-col">{price}</div>
                  )}
                </div>
              </div>

              {toppings &&
                toppings.map(({ name, price }) => (
                  <div className="topping course-row" key={name}>
                    <div className="dish-name">{name}</div>
                    <div className="course-col dish-pricing-col">{price}</div>
                  </div>
                ))}
            </div>
          ))}
          {section.notes && (
            <div className="notes">
              {section.notes.map((note) => (
                <div className="note" key={note}>
                  {note}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
