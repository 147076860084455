import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams, Redirect, NavLink, Route } from "react-router-dom";
import Markdown from "react-markdown";
import classname from "classnames";
import Headers from "../headers";
import Carousel from "../carousel";
import NavMenu from "../navmenu";
import ContentContext from "../ContentContext";
import PageSwitch from "../PageSwitch";
import { FilmsCarousel } from "../film-carousel";
import { useMobileScreenHeight } from "../hooks";
import "./manifesto.scss";

export default function Manifesto() {
  const { mediaType } = useParams();
  const {
    manifesto: { manifesto, medias },
  } = useContext(ContentContext);
  const textRef = useRef(),
    manifestoPageRef = useRef();
  const [chevronUp, setChevronUp] = useState(false);
  const scrollTop = useCallback(
    () =>
      manifestoPageRef.current &&
      manifestoPageRef.current.scroll({
        top: -10000,
        behavior: "smooth",
      }),
    []
  );
  useEffect(() => {
    if (manifestoPageRef.current && textRef.current) {
      const manifestoElement = manifestoPageRef.current;
      const handleScroll = () =>
        textRef.current &&
        manifestoPageRef.current &&
        setChevronUp(
          textRef.current.getBoundingClientRect().y < window.innerHeight / 2 ||
            manifestoPageRef.current.scrollHeight -
              manifestoPageRef.current.scrollTop ===
              manifestoPageRef.current.clientHeight
        );

      manifestoElement.addEventListener("scroll", handleScroll);
      return () => manifestoElement.removeEventListener("scroll", handleScroll);
    }
  }, []);

  const screenHeight = useMobileScreenHeight();
  const [manifestTextStyle, setManifestTextStyle] = useState();
  useEffect(() => {
    if (textRef.current && screenHeight) {
      if (textRef.current.getBoundingClientRect().height > screenHeight) {
        setManifestTextStyle({
          display: "block",
          paddingTop: screenHeight * 0.1,
          paddingBottom: screenHeight * 0.1,
          marginTop: screenHeight * 0.4,
        });
      } else {
        setManifestTextStyle({ marginTop: screenHeight * 0.4 });
      }
    } else {
      setManifestTextStyle(null);
    }
  }, [screenHeight]);
  return (
    <div className="manifesto page" ref={manifestoPageRef}>
      {!mediaType && <Redirect from="/manifesto" exact to="/manifesto/films" />}
      <Headers
        title="Manifesto"
        url="/manifesto"
        description="Serpent A Plume, a space where bright ideas, desire, and
        relentless adventure intersect each other at the oldest square of
        Paris: Place des Vosges. The most fixed and calm lives at the
        Serpent a Plume are spent without any repetition. Secret and
        adventurous lives, here, as elsewhere, are the subject to greater
        restriction"
      >
        <meta property="og:section" content="Fun" />
        {medias.pictures &&
          medias.pictures
            .slice(1)
            .map((picture) => (
              <meta
                key={picture.url}
                property="og:image"
                content={process.env.EXTERNAL_HOST + picture.url}
              />
            ))}
      </Headers>
      <NavMenu color="#ffc400" />
      <PageSwitch
        classNames="fade"
        component="section"
        className="page"
        pathIndex={2}
        style={screenHeight ? { height: screenHeight * 0.6 } : null}
      >
        {Object.keys(medias).map((type) => (
          <Route path={"/manifesto/" + type + "/:media?"} key={type}>
            <div className="absolute top left flex wrapper manifesto-medias">
              {type !== "films" ? (
                <Carousel
                  medias={medias[type]}
                  basePath={"/manifesto/" + type}
                  className={type}
                  dots={false}
                  random={true}
                />
              ) : (
                <FilmsCarousel films={medias[type]} />
              )}
            </div>
          </Route>
        ))}
      </PageSwitch>
      <section
        className="manifesto-text page flex"
        style={manifestTextStyle}
        ref={textRef}
      >
        <div className="manifesto-papersheet flex">
          <div className="wrapper">
            <h1>Manifesto</h1>
            <Markdown>{manifesto}</Markdown>
          </div>
        </div>
        <nav className="media-nav">
          {Object.keys(medias).map((type) => (
            <NavLink
              key={type}
              to={"/manifesto/" + type}
              className={type}
              title={type}
              onClick={scrollTop}
              replace
            />
          ))}
        </nav>
        <button
          className={classname("chevron hide-desktop", { up: chevronUp })}
          onClick={() =>
            chevronUp
              ? scrollTop()
              : textRef.current &&
                textRef.current.scrollIntoView({ behavior: "smooth" })
          }
        />
      </section>
    </div>
  );
}
Manifesto.defaultProps = {
  medias: {
    pictures: [],
    drawings: [],
    movies: [],
  },
};
