import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import ContentContext, { ContentProvider } from "./ContentContext";
import unregisterServiceWorker from "./unregisterServiceWorker";
import { BrowserRouter, Route } from "react-router-dom";
import Front from "./front";
import Contact from "./contact";
import Manifesto from "./manifesto";
import Menu from "./menu";
import Loader from "./loader";
import Fonts from "./fonts";
import PageSwitch from "./PageSwitch";
import NotFound from "./NotFound";

const MainRouter = () => {
  const {
    manifesto: { medias },
  } = useContext(ContentContext);
  return (
    <BrowserRouter>
      <PageSwitch classNames="page-transition" pathIndex={1}>
        <Route path="/contact" exact component={Contact} />
        <Route
          path={"/manifesto/:mediaType(" + Object.keys(medias).join("|") + ")?"}
          component={Manifesto}
        />
        <Route
          path="/menu/:section(cocktails|restaurant|tea)?"
          component={Menu}
        />
        <Route path="/" exact component={Front} />
        <Route path="*" render={() => <NotFound nav />} />
      </PageSwitch>
    </BrowserRouter>
  );
};

const SerpentAPlumeRoot = () => (
  <ContentProvider>
    <Fonts />
    <Loader />
    <MainRouter />
  </ContentProvider>
);

const root = ReactDOM.createRoot(document.body.firstElementChild);
root.render(<SerpentAPlumeRoot />);
unregisterServiceWorker();
