import React from "react";
import NavMenu from "./navmenu";

export default function NotFound({ nav }) {
  return (
    <div className="page flex absolute top left">
      {nav && <NavMenu />}
      <img src="/images/404.png" alt="Not Found (404)" />
    </div>
  );
}
