import React, { useEffect } from "react";
import classnames from "classnames";
import { useIsSafari } from "../hooks";
import "./logo.scss";
import { getCSSVariable } from "../getCSSVariable";

const Logo = ({
  noText,
  white,
  animate = false,
  progress = false,
  fill,
  onAnimationEnd = () => {},
  ...props
}) => {
  const isSafari = useIsSafari();
  const color = fill || (white ? "#FFFFFE" : getCSSVariable("gold")),
    styles =
      !animate && progress !== false
        ? {
            borderInside: {
              strokeDashoffset: progress * -500 + 1120,
            },
            borderOutside: {
              strokeDashoffset: progress * -562 + 1120,
            },
          }
        : {};
  useEffect(() => {
    if (isSafari && animate) {
      const timeoutId = window.setTimeout(onAnimationEnd, 1500);
      return () => window.clearTimeout(timeoutId);
    }
  }, [onAnimationEnd, isSafari, animate]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={classnames("logo", {
        animate: !isSafari && animate,
        fade: isSafari && animate,
        progress: progress !== false,
      })}
      viewBox={"300 45 230 " + (noText ? "375" : "500")}
      onAnimationEnd={onAnimationEnd}
    >
      <g className={"name " + (noText ? "hide" : "")} fill={color}>
        <polygon points="526.771,432.761 498.703,432.761 498.703,435.878 508.059,435.878 508.059,479.539 517.415,479.539 517.415,435.878 526.771,435.878" />
        <polygon points="359.641,435.881 359.641,432.762 334.692,432.761 334.692,479.539 359.641,479.537 359.641,476.419 344.048,476.419 344.048,450.691 353.404,450.691 353.404,447.573 344.048,447.573 344.048,435.881" />
        <polygon points="456.243,435.881 456.243,432.762 431.293,432.761 431.293,479.539 456.243,479.537 456.243,476.419 440.648,476.419 440.648,450.691 450.006,450.691 450.006,447.573 440.648,447.573 440.648,435.881" />
        <polygon points="526.769,494.965 526.769,491.84 501.771,491.84 501.771,538.709 526.769,538.709 526.769,535.584 511.146,535.584 511.146,509.805 520.521,509.805 520.521,506.682 511.146,506.682 511.146,494.965" />
        <polygon points="327.994,470.375 328,470.371 312.593,441.412 319.868,437.215 324.541,445.309 327.241,443.75 321.008,432.955 303.024,443.337 303.041,443.365 303.038,443.367 318.421,472.301 312.015,475.998 307.342,467.904 304.642,469.463 310.875,480.256 328,470.387" />
        <polygon points="404.854,535.584 398.605,535.584 398.605,491.84 389.232,491.84 389.232,538.709 389.232,538.709 404.854,538.709" />
        <path d="M426 447l-16-14h-10v47h9v-13l17-20zm-4 0l-13 16v-27l13 11z" />
        <path d="M385 506l-17-14h-10v47h9v-12l18-21zm-5 0l-13 16v-27l13 11z" />
        <polygon points="393.516,446.586 393.513,446.584 393.514,446.582 377.042,432.761 366.622,432.761 366.622,479.539 375.978,479.539 375.978,435.937 389.125,446.967 376.077,462.518 382.261,479.539 392.218,479.539 384.245,457.635" />
        <polygon points="432.614,491.84 432.614,535.584 431.85,535.584 415.928,491.84 405.953,491.84 423.013,538.71 435.738,538.709 435.738,491.84" />
        <polygon points="490.85,432.761 490.85,476.42 490.086,476.42 474.193,432.761 461.484,432.761 461.484,479.537 464.602,479.537 464.602,435.879 465.373,435.879 481.266,479.541 491.221,479.541 493.967,479.539 493.967,432.761" />
        <path d="M327 539h10l-17-47-17 47h3l3-9 8 9 8-8 2 8zm-10-5l-6-7 6-17 6 18-6 6z" />
        <polygon points="496.039,538.709 478.949,491.744 468.494,520.467 458.044,491.744 440.947,538.709 444.273,538.709 454.716,510.021 465.158,538.709 465.162,538.709 465.178,538.709 465.18,538.709 475.621,510.021 486.064,538.709" />
        <polygon points="474.996,538.709 475.133,538.709 475.048,538.476" />
      </g>
      <g fill="none" stroke={color} strokeMiterlimit="10">
        <g className="top">
          <polyline
            className="inside"
            strokeWidth="6.75"
            points="388.069,179.755 445.444,179.755 445.444,159.505 371.194,159.505 371.194,200.005 465.694,200.005 465.694,139.255 347.569,139.255"
          />
          <polyline
            className="outside"
            strokeWidth="6.75"
            points="347.569,220.255 485.944,220.255 485.944,119.005 347.569,119.005"
          />
          <line
            className="head"
            x1={347.569}
            x2={489.319}
            y1={92.005}
            y2={92.005}
            strokeWidth="6.75"
          />
          <line
            className="large-first"
            x1={347.569}
            x2={347.569}
            y1={223.63}
            y2={135.88}
            strokeWidth="13.5"
          />
          <line
            className="large-last"
            x1={347.569}
            x2={347.569}
            y1={122.38}
            y2={88.63}
            strokeWidth="13.5"
          />
        </g>
        <g className="bottom">
          <line
            x1={347.569}
            x2={347.569}
            y1={372.131}
            y2={237.13}
            strokeWidth="13.5"
          />
          <polyline
            strokeWidth="6.75"
            points="347.569,240.505 485.944,240.505 485.944,321.506 371.194,321.506 371.194,260.755 465.694,260.755 465.694,301.256 391.444,301.256 391.444,281.005 448.819,281.005"
          />
        </g>
        <g className="borders">
          <g className="inside">
            <path
              style={styles.borderInside}
              className="left"
              d="M415.5 71h-91v317h91"
              strokeWidth="6.75"
            />
            <path
              style={styles.borderInside}
              className="right"
              d="M414.5 71h91v317h-91"
              strokeWidth="6.75"
            />
          </g>

          <g className="outside">
            <path
              style={styles.borderOutside}
              className="left"
              d="M415.5 56h-107v348h107"
              strokeWidth="10.12"
            />
            <path
              style={styles.borderOutside}
              className="right"
              d="M414.5 56h107v348h-107"
              strokeWidth="10.12"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Logo;
