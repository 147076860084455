import { useEffect, useState, useMemo } from "react";
const mobileSize = 960;

export const useScript = (url, id) => {
  useEffect(() => {
    if (!url) return;
    if (id && document.getElementById(id)) return;

    const script = document.createElement("script");
    script.src = url;
    script.id = id;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, id]);
};

/**
 * Get windows inner height updated on resize if we're on mobile
 * @return {int?} innerHeight in pixels or null
 */
export function useMobileScreenHeight() {
  const [screenHeight, setScreenHeight] = useState(null);

  const isMobile = useMobileQuery();
  useEffect(() => {
    if (isMobile) {
      const handleResize = () => setScreenHeight(window.innerHeight);
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    } else {
      setScreenHeight(null);
    }
  }, [isMobile]);
  return screenHeight;
}

/**
 * Get if screen is mobile device
 * @return {bool} is screen a mobile device ?
 */
export function useMobileQuery() {
  const mobileQuery = useMemo(
    () => window.matchMedia(`(max-width: ${mobileSize})`),
    []
  );

  const [isMobile, setIsMobile] = useState(mobileQuery.matches);

  useEffect(() => {
    const handleMobileQueryMediaMatch = () => setIsMobile(mobileQuery.matches);
    mobileQuery.addListener(handleMobileQueryMediaMatch);
    return () => mobileQuery.removeListener(handleMobileQueryMediaMatch);
  }, [mobileQuery]);
  return isMobile;
}

export function useIsSafari() {
  const isSafari = useMemo(() => navigator.vendor.match(/apple/i), []);
  return isSafari;
}
